exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-community-js": () => import("./../../../src/pages/about/community.js" /* webpackChunkName: "component---src-pages-about-community-js" */),
  "component---src-pages-about-story-js": () => import("./../../../src/pages/about/story.js" /* webpackChunkName: "component---src-pages-about-story-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-beef-estate-js": () => import("./../../../src/pages/beef/estate.js" /* webpackChunkName: "component---src-pages-beef-estate-js" */),
  "component---src-pages-beef-paddock-js": () => import("./../../../src/pages/beef/paddock.js" /* webpackChunkName: "component---src-pages-beef-paddock-js" */),
  "component---src-pages-beef-phoenix-js": () => import("./../../../src/pages/beef/phoenix.js" /* webpackChunkName: "component---src-pages-beef-phoenix-js" */),
  "component---src-pages-beef-team-js": () => import("./../../../src/pages/beef/team.js" /* webpackChunkName: "component---src-pages-beef-team-js" */),
  "component---src-pages-businesses-js": () => import("./../../../src/pages/businesses.js" /* webpackChunkName: "component---src-pages-businesses-js" */),
  "component---src-pages-careers-create-js": () => import("./../../../src/pages/careers/create.js" /* webpackChunkName: "component---src-pages-careers-create-js" */),
  "component---src-pages-careers-graduate-js": () => import("./../../../src/pages/careers/graduate.js" /* webpackChunkName: "component---src-pages-careers-graduate-js" */),
  "component---src-pages-commodities-specifications-js": () => import("./../../../src/pages/commodities/specifications.js" /* webpackChunkName: "component---src-pages-commodities-specifications-js" */),
  "component---src-pages-commodities-team-js": () => import("./../../../src/pages/commodities/team.js" /* webpackChunkName: "component---src-pages-commodities-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-farms-js": () => import("./../../../src/pages/farms.js" /* webpackChunkName: "component---src-pages-farms-js" */),
  "component---src-pages-feedlots-grassdale-js": () => import("./../../../src/pages/feedlots/grassdale.js" /* webpackChunkName: "component---src-pages-feedlots-grassdale-js" */),
  "component---src-pages-feedlots-pinegrove-js": () => import("./../../../src/pages/feedlots/pinegrove.js" /* webpackChunkName: "component---src-pages-feedlots-pinegrove-js" */),
  "component---src-pages-feedlots-yarranbrook-js": () => import("./../../../src/pages/feedlots/yarranbrook.js" /* webpackChunkName: "component---src-pages-feedlots-yarranbrook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livestock-profit-share-js": () => import("./../../../src/pages/livestock/profit-share.js" /* webpackChunkName: "component---src-pages-livestock-profit-share-js" */),
  "component---src-pages-livestock-sales-js": () => import("./../../../src/pages/livestock/sales.js" /* webpackChunkName: "component---src-pages-livestock-sales-js" */),
  "component---src-pages-livestock-services-js": () => import("./../../../src/pages/livestock/services.js" /* webpackChunkName: "component---src-pages-livestock-services-js" */),
  "component---src-pages-livestock-team-js": () => import("./../../../src/pages/livestock/team.js" /* webpackChunkName: "component---src-pages-livestock-team-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-predictor-js": () => import("./../../../src/pages/predictor.js" /* webpackChunkName: "component---src-pages-predictor-js" */),
  "component---src-pages-sustainability-environment-js": () => import("./../../../src/pages/sustainability/environment.js" /* webpackChunkName: "component---src-pages-sustainability-environment-js" */),
  "component---src-pages-sustainability-people-safety-js": () => import("./../../../src/pages/sustainability/people-safety.js" /* webpackChunkName: "component---src-pages-sustainability-people-safety-js" */),
  "component---src-pages-sustainability-quality-js": () => import("./../../../src/pages/sustainability/quality.js" /* webpackChunkName: "component---src-pages-sustainability-quality-js" */),
  "component---src-pages-sustainability-vision-js": () => import("./../../../src/pages/sustainability/vision.js" /* webpackChunkName: "component---src-pages-sustainability-vision-js" */),
  "component---src-pages-sustainability-welfare-js": () => import("./../../../src/pages/sustainability/welfare.js" /* webpackChunkName: "component---src-pages-sustainability-welfare-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-beef-js": () => import("./../../../src/templates/beef.js" /* webpackChunkName: "component---src-templates-beef-js" */),
  "component---src-templates-business-js": () => import("./../../../src/templates/business.js" /* webpackChunkName: "component---src-templates-business-js" */),
  "component---src-templates-member-js": () => import("./../../../src/templates/member.js" /* webpackChunkName: "component---src-templates-member-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

